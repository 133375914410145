import React from 'react'
import "./about.css"
// import ME from "../../assets/me-about.jpg"
import MESmall from "../../assets/me-about-2.webp" // Importez la version "small" ici
import {FaAward} from "react-icons/fa"
import {FiUsers} from "react-icons/fi"
import {VscFolderLibrary} from "react-icons/vsc"

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-img">
            <img src={MESmall} alt="About-Varin" loading="lazy"/> {/* Utilisez la version "small" ici */}
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className='about__icon'/>
              <h5>Experiences</h5><br />
              <small>1+ Year               <br />IT Experience</small>
              <br />              <br />
              <small>8+ Years               <br />of Management Experience</small>
            </article>

            <article className="about__card">
              <FiUsers className='about__icon'/>
              <h5>Industry experiences</h5>
              <small>Real estate</small><br />
              <small>Bank</small><br />
              <small>Cinema</small><br />
              <small>Fashion</small><br />
              <small>Education</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className='about__icon'/>
              <h5>Projects</h5><br />
              <small>10+ Completed Projects</small>
            </article>
          </div>

          <p>
          Passionate data analyst & web developer full stack with strong
          technical skills, certified by the leading schools. Proven track
          record in management, customer service excellence,
          and marketing & SEO. Commited to delivering high-quality web solutions
          through creativity, attention to detail, and problem-solving abilities.
          </p>
          <br />
          <br />
          <br />

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About
