import React from 'react'
import "./testimonials.css"
import Logo1 from "../../assets/logo-wagon.png";
import Logo2 from "../../assets/logo_escpaubs.png";
import Logo3 from "../../assets/1200px-HEC_Paris.png";
import Logo4 from "../../assets/Logo_de_l'université_Toulouse_-_Jean_Jaurès.png";
import Logo5 from "../../assets/google.png";

const partnersLogos = [
  Logo1,
  Logo2,
  Logo3,
  Logo4,
  Logo5,
];

const Partners = () => {
  return (
    <section id='partners'>
      <h5>Certified by leading organisations</h5>
      <h2>Organisations</h2>

      <div className="container partners__container">
        {
          partnersLogos.map((logo, index) => {
            return (
              <div key={index} className="partner-logo">
                <img src={logo} alt={`Partner ${index + 1}`} loading="lazy" />
              </div>
            )
          })
        }
      </div>
      <p className="partners-invitation">
          For more details on these certifications and diplomas, please visit my <a href="https://www.linkedin.com/in/cyrille-varin/" target="_blank" rel="noopener noreferrer">LinkedIn</a> profile.
        </p>
    </section>
  )
}

export default Partners;
