import React from 'react'
import "./services.css"
import {BiCheck} from "react-icons/bi"

function Services() {
  return (
    <section id="services">
      <h5>What I offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>UI/UX Design</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Provide basic design concepts</p>
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>Create wireframes and prototypes</p>
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>Design user-friendly interfaces</p>
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>Ensure consistent and intuitive user experiences</p>
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>Conduct user research and usability testing</p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>Web Development</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Build responsive and modern websites</p>
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>Develop interactive web applications</p>
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>Integrate with back-end services</p>
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>Optimize for performance and SEO</p>
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>Implement secure authentication and authorization</p>
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>Provide ongoing maintenance and support</p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>Data Science, Analysis & IA</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Scraping and create database</p>
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>Advanced analysis</p>
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>Machine Learning models perfectly adapted to your tasks</p>
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>Dense Neural Network Implementation</p>
            </li>

            <li>
              <BiCheck className='service__list-icon'/>
              <p>Machine Learning Engineering (MLOps) to put your AI models into production</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default Services
