import React from 'react'
import {BsLinkedin} from "react-icons/bs"
import {FaGithub} from "react-icons/fa"
import {BsEnvelopeAtFill} from "react-icons/bs"
import {FaMedium} from "react-icons/fa"
import {FaKaggle} from "react-icons/fa"
import {AiOutlineWechat} from "react-icons/ai"


function HeaderSocials() {
  return (
    <div className="header__socials">
      <a href="https://www.linkedin.com/in/cyrille-varin/" target='_blank'>
        <BsLinkedin />
      </a>
      <a href="https://github.com/DjDc31" target='_blank'>
        <FaGithub />
      </a>
      <a href="https://cyrillevarin.medium.com" target='_blank'>
        <FaMedium />
      </a>
      <a href="https://www.kaggle.com/varincy" target='_blank'>
        <FaKaggle />
      </a>
      <a href="https://u.wechat.com/kKe1AazTzkMf3x2nJnEZIxA" target='_blank'>
        <AiOutlineWechat />
      </a>
      <a href="#contact" target='_blank'>
        <BsEnvelopeAtFill />
      </a>
    </div>
  )
}

export default HeaderSocials
