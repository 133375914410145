import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/animalalouer.png-small.jpg";
import IMG2 from "../../assets/wum.png-small.jpg";
import IMG3 from "../../assets/os-gen.png-small.jpg";
import IMG4 from "../../assets/notsolo.png-small.jpg";
import IMG5 from "../../assets/gravitwave.png-small.jpg";
import IMG6 from "../../assets/caprice.png-small.jpg";
import IMG7 from "../../assets/helyne.png-small.jpg";
import IMG8 from "../../assets/personal.png-small.jpg";

const data = [
  {
    id: 1,
    image: IMG1,
    title: "Louerunanimal.com",
    github: "https://github.com/DjDc31/WUM",
    demo: "http://www.louerunanimal.com",
    technologies: ["Rails", "CSS", "JavaScript", "HTML"],
  },
  {
    id: 2,
    image: IMG2,
    title: "Web Usage Monitor (WUM)",
    github: "https://github.com/DjDc31/WUM",
    demo:"",
    technologies: ["Python"],
  },
  {
    id: 3,
    image: IMG3,
    title: "Fake OS Update",
    github: "https://github.com/DjDc31/fake-update-macos",
    demo: "http://www.update-generator.com/",
    technologies: ["CSS", "JavaScript", "HTML"],
  },
  {
    id: 7,
    image: IMG7,
    title: "Helyne",
    github: "",
    demo: "http://helyne.fr",
    technologies: ["CSS", "JavaScript", "HTML"],
  },
  {
    id: 5,
    image: IMG5,
    title: "Detection Gravitational Waves",
    github: "",
    demo: "https://www.youtube.com/watch?v=SBHDqM84L5A",
    technologies: ["Python", "Kaggle", "PyTorch", "TensorFlow", "Pandas", "Numpy", "Matplotlib"],
  },
  {
    id: 6,
    image: IMG6,
    title: "Caprice Paris",
    github: "",
    demo: "https://caprice-paris.com",
    technologies: ["WordPress", "WooCommerce", "Canva"],
  },
  {
    id: 4,
    image: IMG4,
    title: "Not-Solo (App)",
    github: "https://github.com/LaureneFreyer/not_solo",
    demo: "",
    technologies: ["Rails", "CSS", "JavaScript", "HTML"],
  },
  {
    id: 8,
    image: IMG8,
    title: "Portefolio",
    github: "",
    demo: "http://cyrillevarin.com",
    technologies: ["ReactJS", "CSS", "HTML"],
  },
];

function Portfolio() {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
      {data.map(({ id, image, title, github, demo, technologies }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-img">
                  <img src={image} alt={title} loading="lazy" />
              </div>

              <h3>{title}</h3>

              <div className="portfolio__item-tech">
                {technologies.map(tech => (
                  <span key={tech} className="tech-flair">{tech}</span>
                ))}
              </div>


              <div className="portfolio__item-cta">
                {github && (
                  <a href={github} className="btn" target="_blank" rel="noopener noreferrer">
                    Github
                  </a>
                )}

                {demo && (
                  <a href={demo} className="btn btn-primary" target="_blank" rel="noopener noreferrer">
                    Live Demo
                  </a>
                )}
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
}

export default Portfolio;
